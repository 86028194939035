@media (max-width: 300px) {
    .bm-menu-wrap {
        width: 250px !important;
    }
  }
@media (max-width: 600px) {
    .res_remove_600 {
        display: none;
    }
    .res_display_600 {
        display: flex !important;
    }
  }
  @media (max-width: 1500px) {
    #main-wrapper {
        display: block;

    }
  }
  