.applicants-page {
    background-color: rgba(255, 255, 255, 1);
    padding: 10px;
    box-shadow: 0px 3px 20px #00000014;

}
.applicants-page-input {
    width: 250px;
    height: 43px;
    background-color: rgba(255, 255, 255, 1) !important;
    border: 0.6px solid !important;
    border-color: rgba(221, 221, 221, 1) !important;
    border-radius: 10px !important;
    padding: 10px;
    color: rgba(0, 0, 0, 1) !important;
    font-weight: 600;
    font-size: 15px;
}

.applicants-page-submit-btn {
    width: 150px;
    height: 50px;

    background-color: rgba(0, 140, 119, 1);
    border-radius: 7px;
    box-shadow: 0px 3px 6px #008c774b;
    border: 0.6px solid #008C77;

    color: rgba(255, 255, 255, 1);
    font-size: 15px;
    font-weight: 600;
}