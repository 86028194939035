
 
 .semi-bold-black {
     color: rgba(0, 0, 0, 1);
     font-style: normal;
     font-weight: 600;
 }

 .semi-bold-white {
    color: rgba(255, 255, 255, 1);
    font-style: normal;
    font-weight: 600;
}

 .home-page .title {
    font-size: 20px;
    margin: 0px;
 }
 
 .all-job-btn {
    background-color: rgba(255, 255, 255, 1);
    border: 0.6px solid #dddddd;
    border-radius: 7px;
    box-shadow: 0px 3px 6px #0000004b;

    width: 160px;
    height: 38px;
    gap: 10px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 54px;

    color: #9d9d9d;
    font-size: 15px;
    font-weight: 600;
 }
 
 .btn-2 {
    background-color: rgba(0, 140, 119, 1);
    border-radius: 7px;
    box-shadow: 0px 3px 6px #008c774b;
    border: 0.6px solid #008C77;

    width: 160px;
    height: 38px;
    gap: 10px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    font-size: 15px;
 }
 
 .home-page table {
    box-shadow: 0px 3px 20px #00000015;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 32px;
    margin-top: 50px;
    overflow: hidden;
 }
 
 .home-page table th {
    padding: 25px 40px;
    text-align: center;
    color: rgba(74, 74, 74, 1) !important;
    font-size: 15px !important;
    font-weight: 300 !important;
    font-style: normal !important;
 }

 .home-page table td {
    padding: 25px;
    text-align: center;
    color: rgba(0, 0, 0, 1) !important;
    font-weight: 400 !important;
    font-style: normal !important;
 }

 .home-page table td:nth-child(1)  {
    color: rgba(0, 0, 0, 1) !important;
    font-weight: 600 !important;
    font-style: normal !important;
    cursor: pointer;
 }

 .table.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(252, 252, 252, 1) !important;
 }

.circle {
    background-color: rgba(255, 255, 255, 1) !important;
    border-radius: 13px;
    box-shadow: 0px 3px 6px;
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    cursor: pointer;
}

@media only screen and (max-width: 669px) {
    .all-job-btn {
        width: 97% !important;
        margin-right: 6% !important;
    }
    .btn-2 {
        width: 97% !important;
    }
}

.view-title {
    padding: 40px 0px 46px;
    text-align: center;
    font-size: 15px;
    text-decoration: underline;
    cursor: pointer;
}

.live-jobs-count {
    background-color: rgba(0, 140, 119, 1);
    border-radius:7px;
    width: 100px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 1);
    font-weight: 700;
    font-style: normal;
    font-size: 12px;
}

@media only screen and (max-width: 669px) {
   .new-job-btn {
      margin-left: 50% !important;
      width:  50% !important;
   }
}

.no-data-message {
   background-color: rgba(255, 255, 255, 1);
   box-shadow: 0px 3px 20px #00000015;
   padding: 30px;
   border-radius: 32px;
   height: 150px;
   display: flex;
   align-items: center;
   margin-top: 25px;
}

.home-page table .applicant-name {
    color: rgba(0, 0, 0, 1) !important;
    font-weight: 600 !important;
    font-style: normal !important;
    cursor: pointer;
}

.home-page table, tfoot, td {
   border: none !important;
}
