
.statistics-page-row {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

@media only screen and (max-width: 1200px) {
    .statistics-page-row {
        flex-direction: column;
    }
}

@media(max-width: 768px) {
    .statistics-page-row {
        flex-direction: column;
    }
}
