.teacher-profile {
}

.teacher-profile .teacher-card {
    box-shadow: 0px 3px 20px #00000014;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 28px;
    padding: 14px 15px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.teacher-profile .heading {
    color: black;
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
    min-height: 30px;
}

.teacher-profile p {
    font-weight: 300;
    font-size: 15px;
    color: rgba(160, 160, 160, 1);
    line-height: 15px;
    letter-spacing: -0.6px;
    white-space: nowrap;
}

.teacher-profile h6 {
    line-height: 15px;
    letter-spacing: -0.6px;
    color: black;
    font-size: 15px;
    font-weight: 600;
}

.teacher-profile .p-label {
    width: 30%;
}

.teacher-profile .h-value {
    width: 70%;
    white-space: wrap;
}

.teacher-profile label {
    line-height: 20px;
    letter-spacing: -0.6px;
    color: black;
    font-size: 15px;
    font-weight: 300;
    text-align: center;
}

.teacher-profile .edit-icon {
    position: absolute;
    top: 14px;
    right: 15px;
}

.teacher-profile .dot {
    background-color: #008C77;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin-bottom: auto;
    margin-top: 5px;
}

/* Modals */


.teacher-profile-modal {
    padding: 41px 23px;
}

.teacher-profile-modal .title {
    font-size: 25px;
    font-weight: 600;
    color: black;
}

/* .teacher-profile-modal .col-lg-6,  */
.teacher-profile-modal .col-md-12 
{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    padding: 0px 20px;
}

.teacher-profile-modal .col-lg-6:nth-child(odd) {
    padding-right: 50px;
}

.teacher-profile-modal .col-lg-6:nth-child(even) {
    padding-left: 50px;
}

@media (max-width: 992px) {
    .teacher-profile-modal .col-lg-6:nth-child(odd) {
        padding-right: 20px;
    }
    
    .teacher-profile-modal .col-lg-6:nth-child(even) {
        padding-left: 20px;
    }
}

.teacher-profile-modal p {
    color: rgba(203, 203, 203, 1);
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 5px;
}

.teacher-profile-modal input {
    color: black;
    font-size: 15px;
    font-weight: 600;
    border: 0.6px solid rgba(221, 221, 221, 1);
    background-color: rgba(255, 255, 255, 1);
    border-radius: 10px;
    width: 100%;
    height: 43px;
    padding: 6.6px 12.4px;
}

.teacher-profile-modal .file {
    color: black;
    font-size: 15px;
    font-weight: 600;
    border: 0.6px solid rgba(221, 221, 221, 1);
    background-color: rgba(255, 255, 255, 1);
    border-radius: 10px;
    width: 100%;
    height: 43px;
    padding: 6.6px 12.4px;
}

.teacher-profile-modal input::placeholder {
    color: black;
    font-size: 15px;
    font-weight: 600;
}

.teacher-profile-modal select {
    color: black;
    font-size: 15px;
    font-weight: 600;
    border: 0.6px solid rgba(221, 221, 221, 1);
    background-color: rgba(255, 255, 255, 1);
    border-radius: 10px;
    width: 100% !important;
    height: 43px !important;
    padding: 6.6px 12.4px !important;
}

.teacher-profile-modal .MuiInputBase-input {
    box-sizing: border-box !important;
}

.teacher-profile-modal input[type="checkbox"] {
    border: 0.6px solid rgba(221, 221, 221, 1);
    background-color: rgba(255, 255, 255, 1);
    min-width: 22px;
    min-height: 22px;
    max-width: 22px;
    max-height: 22px;
}


.teacher-profile-modal button {
    border-radius: 7px;
    border: 0.6px solid;
    /* border-color: rgba(221, 221, 221, 1); */
    box-shadow: 0px 3px 6px;
    height: 50px;
    width: 265px;

    font-weight: 600;
    font-size: 15px;
}
