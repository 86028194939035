.logo_wraper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.logo_wraper img {
    width: 57px;
    height: 50px;
    object-fit: cover;
    cursor: pointer;
}