/* .my-profile {
    padding: 40px 66px;
} */

.my-profile-title {
    color: rgba(0, 0, 0, 1);
    font-size: 20px;
    font-weight: 600;
}

.my-profile-main {
    padding-top: 27px;
}

.my-profile-card {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 3px 20px #00000014;
    border-radius: 28px;
    padding: 14px 16px;
    height: 100%;
    min-height: 350px;
}

.my-profile-card-group-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.my-profile-card-group-1-name {
    color: rgba(0, 0, 0, 1);
    font-size: 15px;
    font-weight: 600;
}

.my-profile-card-group-1-specialize {
    color: rgba(0, 0, 0, 1);
    font-size: 15px;
    font-weight: 300;
}

.my-profile-card-description {
    text-align: center;
    margin-top: 37px;
    color: rgba(0, 0, 0, 1);
    font-size: 15px;
    font-weight: 300;
}

.my-profile-card-group-2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.my-profile-card-group-2-title {
    color: rgba(0, 0, 0, 1);
    font-size: 15px;
    font-weight: 600;
}

.my-profile-card-group-2-of-2 {
    margin: 40px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.my-profile-card-group-2-of-2-label {
    font-size: 15px;
    font-weight: 15px;
    color: rgba(160, 160, 160, 1);
}

.my-profile-card-group-2-of-2-value {
    color: rgba(0, 0, 0, 1);
    font-size: 15px;
    font-weight: 600;
}

.my-profile-card-group-3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.my-profile-card-group-3-of-2 {
    margin: 40px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.my-profile-card-group-3-title {
    color: rgba(0, 0, 0, 1);
    font-size: 15px;
    font-weight: 600;
}

.my-profile-card-group-3-of-2-label {
    color: rgba(0, 0, 0, 1);
    font-size: 15px;
    font-weight: 500;
}

/* @media only screen and (max-width: 992px) {
    .my-profile {
        padding: 40px 10px;
    }
} */

@media only screen and (max-width: 992px) {
    .my-profile .row {
        margin-left: unset !important;
        margin-right: unset !important;
    }

    .my-profile .row>* {
        padding-left: unset !important;
        padding-right: unset !important;
    }
}

/* Edit Personal Info  */

.edit-personal-info {
    padding: 35px 23px;
}

.edit-personal-info-group-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.edit-personal-info-group-1-of-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    gap: 18px;
}

.edit-personal-info-group-1-title {
    font-size: 20px;
    font-weight: 600;
    color: rgba(0, 0, 0, 1);
}

.edit-personal-info-group-2 {
    padding-top: 50px;
}

.edit-personal-info-group-2-1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.edit-personal-info-group-2-label {
    color: rgba(203, 203, 203, 1);
    font-size: 15px;
    font-weight: 600;
}

.edit-personal-info-group-2-input-field {
    width: 201px;
    height: 43px;
    background-color: rgba(255, 255, 255, 1);
    border: 0.6px solid;
    border-color: rgba(221, 221, 221, 1);
    border-radius: 10px;
    padding: 10px;
    color: rgba(0, 0, 0, 1);
    font-weight: 600;
    font-size: 15px;
}

.edit-personal-info-group-2-input-field-2 {
    width: 300px;
    height: 43px;
    background-color: rgba(255, 255, 255, 1);
    border: 0.6px solid;
    border-color: rgba(221, 221, 221, 1);
    border-radius: 10px;
    padding: 10px;
    color: rgba(0, 0, 0, 1);
    font-weight: 600;
    font-size: 15px;
}

.edit-personal-info-group-2-text-area-field {
    width: 100%;
    min-height: 43px;
    background-color: rgba(255, 255, 255, 1);
    border: 0.6px solid;
    border-color: rgba(221, 221, 221, 1);
    border-radius: 10px;
    padding: 10px;
    color: rgba(0, 0, 0, 1);
    font-weight: 600;
    font-size: 15px;
}

.edit-personal-info-group-2-2 {
    padding-top: 50px;
}

.edit-personal-info-group-2-2-input-field {
    width: 100%;
    height: 43px;
    background-color: rgba(255, 255, 255, 1);
    border: 0.6px solid;
    border-color: rgba(221, 221, 221, 1);
    border-radius: 10px;
    padding: 10px;
    color: rgba(0, 0, 0, 1);
    font-weight: 600;
    font-size: 15px;
}

.edit-personal-info-group-2-3 {
    padding-top: 50px;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 20px;
}

.edit-personal-info-group-2-3-check-box {
    width: 24px;
    height: 24px;
}

.edit-personal-info-save-cancel-btn {
    padding-top: 50px;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10%;
}

.edit-personal-info-cancel-btn {
    width: 40%;
    height: 50px;

    background-color: rgba(255, 255, 255, 1);
    border: 0.6px solid;
    border-color: rgba(221, 221, 221, 1);
    border-radius: 7px;
    box-shadow: 0px 3px 6px #0000004b;

    color: #9d9d9d;
    font-size: 15px;
    font-weight: 600;
}

.edit-personal-info-save-btn {
    width: 40%;
    height: 50px;

    background-color: rgba(0, 140, 119, 1);
    border-radius: 7px;
    box-shadow: 0px 3px 6px #008c774b;
    border: 0.6px solid #008C77;

    color: rgba(255, 255, 255, 1);
    font-size: 15px;
    font-weight: 600;
}

@media only screen and (max-width: 992px) {
    .edit-personal-info-group-2-1 {
        display: flex;
        flex-direction: column;
        justify-content: unset;
        gap: 20px;
    }
}

@media only screen and (max-width: 1200px) {
    .edit-personal-info-group-2-input-field {
        width: 100%;
    }
    .edit-personal-info-group-2-input-field-2 {
        width: 100%;
    }
}

@media only screen and (max-width: 992px) {
    .edit-personal-info-group-2-2 {
        padding-top: 20px;
    }
}

@media only screen and (max-width: 992px) {
    .edit-personal-info-group-2-3 {
        padding-top: 20px;
    }
}

@media only screen and (max-width: 992px) {
    .edit-personal-info-group-2-responsive {
        padding-top: 20px;
    }
}

@media only screen and (max-width: 992px) {
    .edit-personal-info-save-cancel-btn {
        padding-top: 20px;
    }
}

.edit-about-you-info {
    padding: 35px 23px;
}

.edit-about-you-info-group-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.edit-about-you-info-group-1-title {
    font-size: 20px;
    font-weight: 600;
    color: rgba(0, 0, 0, 1);
}

.edit-about-you-info-group-2 {
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
    align-items: center;
    flex-direction: row;
}

.edit-about-you-info-group-1-of-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    gap: 18px;
}

.edit-about-you-info-group-1-of-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    gap: 18px;
}

.edit-about-you-info-group-2 {
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
    align-items: center;
    flex-direction: row;
}

.edit-about-you-info-group-2-label {
    color: rgba(203, 203, 203, 1);
    font-size: 15px;
    font-weight: 600;
}

.edit-about-you-info-group-2-input-field {
    width: 300px;
    height: 43px;
    background-color: rgba(255, 255, 255, 1);
    border: 0.6px solid;
    border-color: rgba(221, 221, 221, 1);
    border-radius: 10px;
    padding: 10px;
    color: rgba(0, 0, 0, 1);
    font-weight: 600;
    font-size: 15px;
}

.edit-about-you-info-group-2-cancel-save-btn {
    padding-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 5%;
}

.edit-about-you-info-group-2-cancel-btn {
    width: 20%;
    height: 50px;

    background-color: rgba(255, 255, 255, 1);
    border: 0.6px solid;
    border-color: rgba(221, 221, 221, 1);
    border-radius: 7px;
    box-shadow: 0px 3px 6px #0000004b;

    color: #9d9d9d;
    font-size: 15px;
    font-weight: 600;
}

.edit-about-you-info-group-2-save-btn {
    width: 20%;
    height: 50px;

    background-color: rgba(0, 140, 119, 1);
    border-radius: 7px;
    box-shadow: 0px 3px 6px #008c774b;
    border: 0.6px solid #008C77;

    color: rgba(255, 255, 255, 1);
    font-size: 15px;
    font-weight: 600;
}

@media only screen and (max-width: 992px) {
    .edit-about-you-info-group-2 {
        display: flex;
        justify-content: space-between;
        padding-top: 30px;
        align-items: unset;
        flex-direction: column;
    }
}

@media only screen and (max-width: 1200px) {
    .edit-about-you-info-group-2-input-field {
        width: 100%;
        min-width: 200px;
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 992px) {
    .edit-about-you-info-group-2-cancel-save-btn {
        align-items: center;
        flex-direction: column;
        gap: 20px;
    }
    .edit-about-you-info-group-2-cancel-btn {
        width: 70%;
    }
    .edit-about-you-info-group-2-save-btn {
        width: 70%;
    }
}

.view-document-info {
    padding: 35px 23px;
}

.add-document {
    padding: 35px 23px;
}

.add-document-group-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-document-group-1-title {
    font-size: 20px;
    font-weight: 600;
    color: rgba(0, 0, 0, 1);
}

.add-document-group-2 {
    padding-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.add-document-group-2-label {
    font-size: 15px;
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
}

.add-document-save-cancel-btn {
    padding-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 5%;
}

.add-document-cancel-btn {
    width: 20%;
    height: 50px;

    background-color: rgba(255, 255, 255, 1);
    border: 0.6px solid;
    border-color: rgba(221, 221, 221, 1);
    border-radius: 7px;
    box-shadow: 0px 3px 6px #0000004b;

    color: #9d9d9d;
    font-size: 15px;
    font-weight: 600;
}

.add-document-save-btn {
    width: 20%;
    height: 50px;

    background-color: rgba(0, 140, 119, 1);
    border-radius: 7px;
    box-shadow: 0px 3px 6px #008c774b;
    border: 0.6px solid #008C77;

    color: rgba(255, 255, 255, 1);
    font-size: 15px;
    font-weight: 600;
}

@media only screen and (max-width: 992px) {
    .add-document-save-cancel-btn {
        align-items: center;
        flex-direction: column;
        gap: 20px;
    }
    .add-document-cancel-btn {
        width: 50%;
    }
    .add-document-save-btn {
        width: 50%;
    }
}

.pg-viewer-wrapper {
    overflow-y: hidden !important;
}

.edit-personal-info-group-1-of-2 {
    background-color: #FFEBE8;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 15px;
    gap: 11px;
    border-radius: 6px;
}

.edit-personal-info-group-1-of-2-label {
    color: #fc3e3e;
    font-size: 12px;
    font-weight: 600;
}

.edit-about-you-info-group-1-of-3 {
    background-color: #FFEBE8;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 15px;
    gap: 11px;
    border-radius: 6px;
}

.edit-about-you-info-group-1-of-3-label {
    color: #fc3e3e;
    font-size: 12px;
    font-weight: 600;
}