.responsive-setting {
    display: flex;
    justify-content: center;
}

.setting-form {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 3px 20px #00000015;
    border-radius: 32px;
    margin-top: 51px;
    width: 500px;
    height: 600px;
}

.setting-title {
    font-size: 25px;
    font-style: normal;
    color: rgba(0, 0, 0, 1);
    font-weight: 600;
}

.setting-main {
    padding-top: 50px;
    margin-left: 78px;
    display: flex;
    flex-direction: column;
}

.setting-label-1 {
    color: rgba(0, 0, 0, 1);
    font-size: 15px;
    font-weight: 600;
    font-style: normal;
}

.setting-input-field {
    margin-top: 10px;
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid;
    border-color: rgba(112, 112, 112, 1);
    border-radius: 6px;
    width: 350px;
    height: 40px;
    font-size: 15px;
    padding-left: 10px;
}

.setting-label-2 {
    margin-top: 15px;
}

.setting-btn {
    margin-top: 50px;
    display: flex;
    justify-content: center;
}

.setting-submit-btn {
    background-color: rgba(0, 140, 119, 1);
    border-radius: 7px;
    box-shadow: 0px 3px 6px #008c774b;
    border: 0.6px solid #008C77;
    width: 200px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color: rgba(255, 255, 255, 1);
    font-style: normal;
    font-weight: 600;
}




/* @media only screen and (max-width: 669px) {
    .setting {
        padding: 30px 10px !important;
    }
} */

@media only screen and (max-width: 669px) {
    .responsive-setting {
        display: unset !important;
        justify-content: unset !important;
    }
}

@media only screen and (max-width: 669px) {
    .setting-form {
        width: 100% !important;
    }
    .hide-profile-form {
        width: 100% !important;
    }
}

@media only screen and (max-width: 669px) { 
    .setting-input-field {
        width: 95% !important;
    }
}

@media only screen and (max-width: 669px) {
    .setting-main {
        margin-left: 10px !important;
    }
}


.hide-profile-form {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 3px 20px #00000015;
    border-radius: 32px;
    margin-top: 51px;
    width: 500px;
    height: 200px;
    /* padding: 20px; */
    padding: 4%;
}

.hide-profile-check-box {
    width: 20px;
    height: 20px;
}