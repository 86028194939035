/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 22px;
    height: 18px;
    left: 19px;
    top: 23px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #008c77;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #027161;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #ffffff;
    padding: 0px;
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0px;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
  #react-burger-cross-btn {
    display: none;
  }
  .bm-cross {
    display: none;
  }
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    top: 0px;
    left: 0px;
  }
  .App-logo {
    position: absolute;
    animation: rotate 200s cubic-bezier(.49,.05,.32,1.04) infinite alternate;
    transform-origin: 100% 50%;
    }
  @keyframes App-logo-spin2 {
    50% {
        transform: rotate(0deg);
    }
  }
  .App-logo2 {
    position: absolute;
    animation: rotate 400s cubic-bezier(.49,.05,.32,1.04) infinite alternate;
    transform-origin: 50% 100%;
    }
  @keyframes rotate {
    50% {
        transform: rotate(360deg);
      }
  }