.res_display_600 {
    display: none !important;
}

/*Header Styling*/

.dashboard_header {
    width: 100%;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 1px 0px 8px rgba(128, 128, 128, 0.429);
    padding: 5px;
    min-height: 65px;
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    z-index: 101;
}
.right_header_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    padding: 5px;
}
.right_header_wrapper label {
    margin-bottom: 0px;
}
.right_header_wrapper .notification_dropdown {
    position: relative;
}
.right_header_wrapper .badge {
    position: absolute;
    top: 0px;
    right: -3px;
    padding: 5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.page_title {
    font-size: 21px;
    font-weight: bold;
    margin-left: 62px;
    color: #104b08;
}
.get_the_best {
    padding: 5px 15px;
    border: none;
    border-radius: 5px;
    /* color: #fff; */
    background-color: transparent;
    /* font-size: 14px; */
}
.side_menu_wrapper {
    display: flex;
    flex-direction: column;
}
.sideMenu_header {
    display: flex;
    min-height: 150px;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: #008c77;
    position: relative;
}
.sideMenu_header label {
    z-index: 100;
    text-shadow: 3px 5px 20px rgba(0, 0, 0, 0.52);
    font-weight: 700;
    color: #ffffff;
    font-size: 18px;
    /* background-color: #719f6a; */
    padding: 0px;
}
.fade_effect {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.1);
}
.sideMenu_header svg {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    position: absolute;
    filter: blur(0.2px);

}
.sideMenu_body {
    padding: 10px;
    display: flex;
    flex-direction: column;
}
.sideMenu_body .menu-item {
    width: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    color: #008c77;
}
.sideMenu_body .menu-item:hover {
    color: #024f43;
}
#main-wrapper {
    display: flex;
    justify-content: center;
}
.content-body .container-fluid {
    padding: 2.5rem;
}
/*Header Styling*/
